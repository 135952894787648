/*=========================================================================================
  File Name: moduleCountryMutations.js
  Description: Country Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.ServiceProviderRequests.unshift(item)
  },
  SET_ServiceProviderRequests(state, ServiceProviderRequests) {
    debugger;
    try {
     
      state.ServiceProviderRequests = ServiceProviderRequests;
      
    } catch (error) {
      console.error("Failed to parse ServiceProviderRequests data JSON:", error);
      state.ServiceProviderRequests = [];
    }
  },
  UPDATE_Country(state, ServiceProviderRequest) {
    debugger
    const ServiceProviderRequestIndex = state.ServiceProviderRequests.findIndex((p) => p.ID == ServiceProviderRequest.Id)
    Object.assign(state.ServiceProviderRequests[ServiceProviderRequestIndex], ServiceProviderRequest)
  },
  REMOVE_ITEM(state, itemId) {
      const ItemIndex = state.ServiceProviderRequests.findIndex((p) => p.ID == itemId)
      state.ServiceProviderRequests.splice(ItemIndex, 1)
  },
}
